html {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato", Arial, Helvetica, sans-serif;
    background-color: #e3e3e3;
}

h1,
h2,
h3,
h4 {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    display: block;
    margin: 0px !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.contenedorFormulario {
    margin-top: 0px;
    width: 100%;
    text-align: left;
    padding: 25px 25px 0px;
    color: #000;
    background: #e3e3e3;
}

.contenedorFormulario h3 {
    font-size: 28px;
    line-height: 1.5;
    color: inherit;
    font-weight: bold;
}

.filaFormulario {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.filaFormulario input,
select {
    padding-left: 22px;
    display: block;
    width: 100%;
    height: 50px;
    padding: 12px 15px;
    border: 1px solid #f5f5f5;
    background-color: white;
}

.filaFormulario span {
    margin-bottom: 10px;
}

.boton {
    width: 100%;
    color: #fff;
    background-color: #3F51B5;
    margin-top: 15px;
    padding: 15px 38px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 2px;
    cursor: pointer;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 500;
    border-width: 0;
}

.boton:hover {
    background-color: #716286;
}

.imagenWsp {
    width: 50px;
}

.registrado {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.table button {
    background-color: #3F51B5;
    border-radius: 2px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    border-width: 0;
    color: #fff;
    padding: 5px 7px 5px 7px;
}

.botonesFiltrado {
    display: flex;
    justify-content: space-between;
}

.botones {
    margin-top: 30px;
    display: flex;    
}

.offcanvas {
    width: 280px !important;
}

.marginTop {
    margin-top: 20px;
}

.mensajeWsp {
    margin-bottom: 15px;
}

.mensajeWsp {
    width: 250px;
    height: 100px;
}

.precio {
    width: 50%;
}

.setearPrecio {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qr {
    display: flex;
    flex-direction: column;
}

.ocupado {
    background: rgb(103, 103, 109);

}

.cliente {
    background: rgb(80, 119, 80);
}

.promo {
    background: rgb(13, 146, 255);
}

.graphic {
    background-color: #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graphic h1 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.grafico {
    display: inline-table;
    height: 100px;
    width: 100%;
}

.loading {
    margin-top: 100px;
    background-color: #e3e3e3;
}

.espera {
    background-color: #e3e3e3;
}

.espera p {
    margin-top: 30px;
    text-align: center;
}

.cabecera {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visible {
    display: none;
}

.filaFormulario p {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.havePromo {
    color: green;
}

.doesntHavePromo {
    color: red;
}

.booked p {
    font-size: 20px;
}

.footer {
    width: 100%;
    background-color: #3F51B5;
    position: absolute;
    bottom: 0;
    color: white;
    text-align: center;
    padding-top: 5px;
}

.promotion {
    background-color: #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promotion .botones {
    display: flex;
}

.promotion .dias {
    height: 30px;
    width: 45px;
    margin: auto 10px auto 10px;
    text-align: center;
}

.promotion span {
    margin-left: 10px;
    font-size: 20px;
}
.chk-promotion span{
    padding: 0;
}

@media (max-width: 600px) {
    .contenedorFormulario {
        margin-left: auto;
        margin-right: auto;
    }
}